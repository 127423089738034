import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Game from './Game';
import './App.css';

const AppContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  text-align: center;
`;

const GameWrapper = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Score = styled.div`
  text-align: center;
  flex: 1;
`;

const ScoreTitle = styled.h2`
  font-size: 32px;
`;

const ScoreValue = styled.h3`
  font-size: 24px;
`;

const Disclaimer = styled.p`
  flex: 1;
  padding: 0 25px;
`;

function App() {
  const [didTouch, setDidTouch] = useState(false);
  const [playerScore, setPlayerScore] = useState(0);
  const [studScore, setStudScore] = useState(0);

  useEffect(() => {
    function onTouch() {
      setDidTouch(true);
    }

    document.addEventListener('touchstart', onTouch);
    return () => document.removeEventListener('touchstart', onTouch);
  }, []);

  return (
    <AppContainer>
      <Title>Click The Stud {_.repeat('Finder ', playerScore + 1)}</Title>
      <GameWrapper>
        {didTouch ? null : (
          <Game
            playerScore={playerScore}
            studScore={studScore}
            onPlayerPoint={() => setPlayerScore(playerScore + 1)}
            onStudPoint={() => setStudScore(studScore + 1)}
          />
        )}
      </GameWrapper>
      <Footer>
        <Score>
          <ScoreTitle>You</ScoreTitle>
          <ScoreValue>{playerScore}</ScoreValue>
        </Score>
        <Disclaimer>
          {didTouch && (
            <span>
              This site only works with a mouse on desktop, sorry. Get off your
              phone you whippersnapper. Back in my day we browsed the Chaosnet
              on our Lisp machines.
            </span>
          )}
        </Disclaimer>
        <Score>
          <ScoreTitle>Stud Finder</ScoreTitle>
          <ScoreValue>{studScore}</ScoreValue>
        </Score>
      </Footer>
    </AppContainer>
  );
}

export default App;
