import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import confetti from 'canvas-confetti';

import studfindrImage from './studfindr.png';

function fleeSpeed(score: number): number {
  const speeds = [3, 2, 1, 0.9, 0.8, 0.7, 0.6, 0.5];

  if (score < speeds.length) {
    return speeds[score];
  }

  return 0.5 * (1 / Math.pow(1.5, score - 8));
}

function fleeThreshold(score: number): number {
  return 50 - 50 / Math.exp(score / 10);
}

type Props = {
  playerScore: number;
  studScore: number;
  onPlayerPoint: () => void;
  onStudPoint: () => void;
};

const GameBoard = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Stud = styled.div`
  position: absolute;
  cursor: pointer;
  transition: ${({ score }: { score: number }) =>
    `top ${fleeSpeed(score)}s ease-in-out, left ${fleeSpeed(
      score
    )}s ease-in-out`};
`;

const StudFinder = styled.img`
  position: absolute;
`;

const STUDFINDER_WIDTH = 43;
const STUDFINDER_HEIGHT = 100;
const STUDFINDER_OFFSET = 20;

function layOutImage(count: number, i: number): { top: number; left: number } {
  const cols = Math.ceil(Math.sqrt(count));
  const row = Math.floor(i / cols);
  const col = i % cols;

  return {
    top: STUDFINDER_OFFSET * row,
    left: STUDFINDER_OFFSET * col,
  };
}

function studSize(count: number): { width: number; height: number } {
  const { top, left } = layOutImage(count, count - 1);
  return {
    width: STUDFINDER_WIDTH + left,
    height: STUDFINDER_HEIGHT + top,
  };
}

export default function Game({
  playerScore,
  studScore,
  onPlayerPoint,
  onStudPoint,
}: Props) {
  const [studLocation, setStudLocation] = useState({
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    setStudLocation({
      x: (window.innerWidth - STUDFINDER_WIDTH) / 2,
      y: (window.innerHeight - STUDFINDER_HEIGHT) / 2,
    });
  }, []);

  const gameBoardRef = useRef<HTMLDivElement>(null);
  const studRef = useRef<HTMLDivElement>(null);
  const isFleeing = useRef(false);
  const playerScoreRef = useRef(playerScore);

  useEffect(() => {
    playerScoreRef.current = playerScore;
  }, [playerScore]);

  function flee() {
    if (isFleeing.current) {
      return;
    }

    const boardWidth = gameBoardRef.current!.offsetWidth;
    const boardHeight = gameBoardRef.current!.offsetHeight;

    const studWidth = studRef.current!.offsetWidth;
    const studHeight = studRef.current!.offsetHeight;

    const maxLeft = boardWidth - studWidth;
    const maxTop = boardHeight - studHeight;

    // compute 10 possible locations, then pick the farthest away
    const candidates: { x: number; y: number }[] = _.range(10).map(() => ({
      x: Math.random() * maxLeft,
      y: Math.random() * maxTop,
    }));

    const studCenter = {
      x: Number(studRef.current!.offsetLeft) + studWidth / 2,
      y: Number(studRef.current!.offsetTop) + studHeight / 2,
    };

    setStudLocation(
      _.maxBy(candidates, (c) =>
        Math.sqrt(Math.abs(c.x - studCenter.x) * Math.abs(c.y - studCenter.y))
      )!
    );

    isFleeing.current = true;
  }

  useEffect(() => {
    function onMouseMove(evt: MouseEvent) {
      const studPos = studRef.current!.getBoundingClientRect();
      const threshold = fleeThreshold(playerScoreRef.current);

      if (
        (evt.clientX > studPos.left &&
          evt.clientX < studPos.right &&
          evt.clientY > studPos.top &&
          evt.clientY < studPos.bottom) ||
        Math.abs(studPos.left - evt.clientX) < threshold ||
        Math.abs(studPos.right - evt.clientX) < threshold ||
        Math.abs(studPos.top - evt.clientY) < threshold ||
        Math.abs(studPos.bottom - evt.clientY) < threshold
      ) {
        flee();
      }
    }

    document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
  }, []);

  const studfinders = playerScore + 1;
  const { width, height } = studSize(studfinders);

  return (
    <GameBoard ref={gameBoardRef}>
      <Stud
        style={{
          top: studLocation.y,
          left: studLocation.x,
          width: width,
          height: height,
        }}
        score={playerScore}
        ref={studRef}
        onClick={() => {
          onPlayerPoint();

          const colors = ['#AB0000', '#000000'];
          confetti({
            particleCount: 200,
            angle: 60,
            spread: 55,
            origin: { x: -0.1 },
            colors: colors,
          });
          confetti({
            particleCount: 200,
            angle: 120,
            spread: 55,
            origin: { x: 1.1 },
            colors: colors,
          });
        }}
        onTransitionEnd={(evt) => {
          if (evt.propertyName === 'left') {
            isFleeing.current = false;
            onStudPoint();
          }
        }}
      >
        {_.range(0, studfinders).map((i) => (
          <StudFinder
            src={studfindrImage}
            style={layOutImage(studfinders, i)}
            alt=""
            key={i}
          />
        ))}
      </Stud>
    </GameBoard>
  );
}
